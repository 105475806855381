import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = ["iconFrame"]
  change(event) {

    this.iconFrameTarget.src = event.target.selectedOptions[0].dataset.image


  }
}
