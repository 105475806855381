// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.

// import { Application } from "stimulus"
import { Application } from "@hotwired/stimulus"

import { definitionsFromContext } from "stimulus/webpack-helpers"



const application = Application.start()
import { Dropdown } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)


const context = require.context("controllers", true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))
