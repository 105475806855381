import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["theform"]

  static values = { menu: String } 

  change_sort_heading() { 
document.getElementById("sorttext").innerText = this.menuValue
  }

  set_default_sort_option() { 
    document.getElementById("sorttext").innerText = "by location"

  }

  clear_form_field() { 
    document.getElementById("form_field").value = ""
  }

}
