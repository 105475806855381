import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggled"]
  static classes = ["toggle"]

  toggle(event) {
    event.preventDefault()
    // Unblurring focused target if there is one
    if (event.target) {
      document.activeElement.blur()
    }

    this.toggledTargets.forEach(
      (toggled) => toggled.classList.toggle(this.toggleClass)
    )
  }
}
