import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = ["cargoSelect", "boxcarFields", "tankerFields", "coveredHopperFields", "autorackFields", "centerbeamFields", "flatcarFields", "hopperFields", "gondolaFields" ]

  static values = { car: String } 

  connect() { 
    this.show_only_selected_car_type_fields(this.carValue)
}

  change(event) {
    let car_type = event.target.selectedOptions[0].value;
    let target = this.cargoSelectTarget.id
    
    get(`/cargo_options/cargo_options_for_cartype?target=${target}&cartype=${car_type}`, {
      responseKind: "turbo-stream"
    })
    this.show_only_selected_car_type_fields(car_type)
  }

  show_only_selected_car_type_fields(car_type) {
    let all_feature_fields = document.querySelectorAll('.car_features')
    all_feature_fields.forEach(function (car_feature) {
      car_feature.classList.add("hidden");
    });

    if (car_type == "BOXCAR") {
      this.boxcarFieldsTarget.classList.remove("hidden");
    } else if (car_type == "TANKER") {
      this.tankerFieldsTarget.classList.remove("hidden");
    } else if (car_type == "COVERED HOPPER") {
      this.coveredHopperFieldsTarget.classList.remove("hidden");
    } else if (car_type == "AUTORACK") {
      this.autorackFieldsTarget.classList.remove("hidden");
    } else if (car_type == "HOPPER") {
      this.hopperFieldsTarget.classList.remove("hidden");
    } else if (car_type == "CENTERBEAM") {
      this.centerbeamFieldsTarget.classList.remove("hidden");
    } else if (car_type == "FLATCAR") {
      this.flatcarFieldsTarget.classList.remove("hidden");
    } else if (car_type == "GONDOLA") {
      this.gondolaFieldsTarget.classList.remove("hidden");
    } 

 }



}

