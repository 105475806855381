import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="industry-selection"
export default class extends Controller {

  static targets = ["areaSection"]

  static values = {
    homeRailroadId: Number,
    industryRailroadId: Number
  }

  connect() {
    console.log("IM IN INDUSTRY HIDE AREA CONTROLLER");
    // this.hideUnrelatedUI()

    if (this.homeRailroadIdValue == this.industryRailroadIdValue) {
      // do NOT hide the area....

    } else {
      // definitely HIDE the area...
      this.areaSectionTarget.hidden = true

    }

  }





}
