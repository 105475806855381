import { Controller } from "stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {

  static targets = ["complete_button", "incomplete_button", "trainstatus"]

  change() {
    let train_status = this.trainstatusTarget.dataset.phase

    switch(train_status) {

      case "BUILDING":
        var engine_tasks_container = document.querySelector('.engine_tasks')
        var engine_checkboxes = engine_tasks_container.querySelectorAll('input[type="checkbox"].checkbox');
        var checked_engine_checkboxes = engine_tasks_container.querySelectorAll('input:checked')

        if (engine_checkboxes.length == checked_engine_checkboxes.length) {
          this.complete_buttonTarget.classList.remove("hidden")
          this.incomplete_buttonTarget.classList.add("hidden")
        }
     else {
          this.complete_buttonTarget.classList.add("hidden")
          this.incomplete_buttonTarget.classList.remove("hidden")
        }
        break;

      case "SWITCHING":
        break;

      case "RETURNING":
        let checkboxes = document.querySelectorAll('input[type="checkbox"].checkbox');
        let checked_checkboxes = document.querySelectorAll('input:checked')
        if (checkboxes.length == checked_checkboxes.length) {
          this.complete_buttonTarget.classList.toggle("hidden")
          this.incomplete_buttonTarget.classList.toggle("hidden")
        }
         else {
          this.complete_buttonTarget.classList.add("hidden")
          this.incomplete_buttonTarget.classList.remove("hidden")
        }
        break;
    }
  }

  submitCheckboxOnClick(event) {
    const parent_form = event.target.parentElement;
    Turbo.navigator.submitForm(parent_form)

    // Below is the solution using requestSubmit and passing in the submit button to a form that has a hidden Submit button.But this would not work with Safari to trigger my Turbostreams or Chrome on IOS.Turbo 7.1 may have a submit_request_polyfill but I am using Turbo 7.01 and am not sure how to upgrade.In the meantime I found a thread online that recommended using 'import {navigator}' at the top of your stimulus Controller, and then 'Turbo.navigator.submitForm(form_object' to submit a form.And it seems to work so Im sticking with that but here is the other solution in case there is ever a problem. 
    
    // const parent_form = event.target.parentElement;
    // const submitButton = parent_form.querySelector('[type="submit"]')
    // parent_form.requestSubmit(submitButton)
    // HK: remember to add a hidden submit form to your HTML if you try this approach. 
    //   <%= form.submit class: "hidden" %>

  }

}
