import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = ["myForm"]
  change(event) {
    console.log("IM IN THE CANCEL NEW FORM!!!");
    let target = this.myFormTarget.id
    console.log("HERE IS THE TRGET");
    console.log(target);

    var element = document.getElementById(target);
element.remove()

    
    
  }
}