import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = ["cargoSelect"]
  change(event) {
    let car_type = event.target.selectedOptions[0].value;
    let target = this.cargoSelectTarget.id

    get(`/cargo_options/material_cargo_options_for_cartype?target=${target}&cartype=${car_type}`, {
      responseKind: "turbo-stream"
    })

  }
}