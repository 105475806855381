import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="industry-selection"
export default class extends Controller {

  static targets = ["railroadSelect", "interchangeSelect", "yardlineSelect"]

  static values = { homeRailroadId: Number } 

  connect() {
    this.hideUnrelatedUI()
  }

  change() { 
    this.hideUnrelatedUI()

  }


  hideUnrelatedUI() {
    let selected_railroad_id = this.railroadSelectTarget.selectedOptions[0].value;
    let area_section = document.getElementById("area_section")
// NOTE: I had to target the area section by using its ID instead of a Stimulus target because that section is outside of the scope of this stimulus controller...this controller is focused on the industry form, but the area section appears on the industry show page (that the form becomes a part of via inline-editing). I ran into complications in trying to add this stimulus controller to the Industry show page (for instance, the show page would run hideUnrelatedUI on connect even though the form wasn't present) and so just grabbing the area section by an old fashioned ID was way easier. 


    if (selected_railroad_id == this.homeRailroadIdValue) {
      this.yardlineSelectTarget.hidden = false
      this.interchangeSelectTarget.hidden = true
      area_section.hidden = false

    } else {
      this.interchangeSelectTarget.hidden = false
      this.yardlineSelectTarget.hidden = true
      area_section.hidden = true

    }
}

}
